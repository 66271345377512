<template>
  <div ref="containerRef" class="magnifier-container">
    <!-- Base Image -->
    <AppImage
      ref="imageRef"
      :src="imageSrc"
      class="base-image"
      v-bind="$attrs"
      @mouseenter="showMagnifier"
      @mouseleave="hideMagnifier"
      @mousemove="onMouseMove"
    />

    <!-- Popover Div -->
    <div ref="popoverRef" popover class="magnifier-popover">
      <div class="magnified-content">
        <img :src="imageSrc" alt="Magnified" class="magnified-image" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computePosition, flip, autoUpdate } from '@floating-ui/dom';
import type AppImage from './global/AppImage.vue';
defineProps({
  imageSrc: {
    type: String,
    required: true,
  },
});

const containerRef = ref<HTMLDivElement | null>(null);
const imageRef = ref<InstanceType<typeof AppImage> | null>(null);
const popoverRef = ref<HTMLDivElement | null>(null);

function showMagnifier() {
  (popoverRef.value as any)?.showPopover();
  init();
}

function hideMagnifier() {
  (popoverRef.value as any)?.hidePopover();
  kill();
}

const updatePosition = () => {
  if (popoverRef.value && imageRef.value?.$el) {
    computePosition(imageRef.value.$el, popoverRef.value, {
      placement: 'right',
      middleware: [flip()],
    }).then(({ x, y }) => {
      if (!popoverRef.value?.style) return;

      popoverRef.value.style.left = `${x}px`;
      popoverRef.value.style.top = `${y}px`;
    });
  }
};

let cleanup: undefined | (() => void) = undefined;

const init = () => {
  const startup = () => {
    if (!popoverRef.value) return;
    if (!imageRef.value?.$el) return;
    return autoUpdate(imageRef.value?.$el, popoverRef.value, updatePosition);
  };

  cleanup = startup();

  onUnmounted(() => {
    kill();
  });
};

const kill = () => {
  cleanup?.();
};
</script>

<style scoped>
.magnifier-container {
  position: relative;
  display: inline-block;
}

.base-image {
  display: block;
  width: 100%;
  height: 100%;
  cursor: crosshair;
}

.magnifier-popover {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  background: transparent;
}

.magnified-content {
  @apply shadow-custom bg-white;

  pointer-events: none;
  position: relative;
}

.magnified-image {
  max-width: 100vw;
  max-height: 100vh;
}
</style>
