import { useQuery } from 'villus';
import {
  GetSmartHomesPagesDocument,
  type SmartHomePagesOutput,
  GetSmartHomesPageByUrlKeyDocument,
} from '~/graphql/SmartHomes';

export function useFetchSmartHomesPages() {
  const pages = ref<SmartHomePagesOutput[]>([]);
  const { execute } = useQuery({
    query: GetSmartHomesPagesDocument.toString(),
  });
  const fetchPages = async () => {
    const { data, error } = await execute();
    if (error) {
      console.error(error);
    }

    if (data) {
      pages.value = (data.getSmartHomesPages as SmartHomePagesOutput[]) || [];
    }
  };
  return { pages, fetchPages };
}

export function useGetSmartHomesPageContent() {
  const content = ref<SmartHomePagesOutput[]>([]);
  const { execute } = useQuery({
    query: GetSmartHomesPageByUrlKeyDocument.toString(),
  });
  const fetchPages = async (id: string) => {
    const { data, error } = await execute({ variables: { urlKey: id } });
    if (error) {
      console.error(error);
    }

    if (data) {
      content.value = (data.getSmartHomesPageByUrlKey as SmartHomePagesOutput[]) || [];
    }
  };
  return { content, fetchPages };
}
