<template>
  <div class="relative flex flex-col">
    <picture v-if="isMobile" class="md:hidden -mb-30 mx-6 z-[1]">
      <img
        src="/find-the-best-match-visual.jpg"
        alt=""
        class="rounded-5xs object-cover aspect-square"
        width="326"
        height="326"
      />
    </picture>

    <div class="bg-coloration text-left rounded-6xs relative px-6 py-8 lg:px-15 lg:py-14 z-0 pt-36">
      <header class="max-w-45 lg:max-w-100">
        <h2 class="highlight font-bold text-white text-lg lg:text-2xl">{{ $t('title') }}</h2>
      </header>
      <p class="max-w-45 lg:max-w-none mt-5 text-white text-base">{{ $t('desc') }}</p>

      <AppButton variant="inverted" class="mt-5 lg:mt-7 w-full lg:w-64 h-14" @click="showShoppingGuide = true">
        <span class="text-primary-700 font-display text-lg-1 tracking-wide font-bold"> {{ $t('cta') }} </span>
      </AppButton>
      <img
        v-if="!isMobile"
        src="/find-the-best-match-visual.jpg"
        alt=""
        width="388"
        height="388"
        class="hidden md:block absolute [ h-48 w-40 lg:h-[388px] lg:w-[388px] ] bottom-22 lg:bottom-0 lg:-top-10 right-6 lg:right-24 -z-1 rounded-2xl"
      />
    </div>
  </div>
  <DialogAcCalculator v-model="showShoppingGuide" />
</template>
<script setup lang="ts">
const showShoppingGuide = ref();
const { isMobile } = useDevice();
const { t: $t } = useI18n({
  useScope: 'local',
});
</script>
<i18n>
{
    "en": {
        "title": "Don’t Know What is the Right Fit For You?",
        "desc": "Use our guide and to get the most compatible prooducts for you",
        "cta": "Find The Best Match"
    },
    "ar": {
        "title": "لا تعرف الخيار الأمثل لك؟",
        "desc": "استخدم دليلنا لمعرفة المنتج الأمثل من أجلك.",
        "cta": "اعثر على الأكثر مناسبة لك"
  }
}
</i18n>
<style lang="postcss" scoped>
.highlight {
  @apply relative inline z-0;
}
.highlight::after {
  content: '';
  height: 57px;
  width: 160px;
  background: url('~/assets/icons/path.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -10px;
  right: -16px;
  z-index: -1;
}

.bg-coloration {
  background: linear-gradient(270deg, #5dbfee 0%, #1ca4e6 100%);
  border-radius: 60px;
}
</style>
