<template>
  <form class="text-start" @submit="onSubmit">
    <MainTitle el="h2" class="mt-10 md:text-left">
      <template #before>
        <span class="md:text-2.6xl text-1.6xl">
          {{ $t(acHorsePower ? 'check' : 'ourShopping') }}
        </span>
      </template>
      <span class="md:text-primary-A900 text-1.6xl md:text-2.6xl ml-2">
        {{ $t(acHorsePower ? 'compatibility' : 'guide') }}
      </span>
    </MainTitle>
    <p class="mt-10 text-base text-secondary-700 max-w-150">{{ $t('fill') }}</p>
    <!-- 
        Used flex instead of grid even though grid is a better fit here because of 
        this bug: https://stackoverflow.com/questions/51076747/grid-layout-on-fieldset-bug-on-chrome 
      -->
    <fieldset class="mt-8 pb-5 border-b-1 border-tertiary-A600 text-primary-A900">
      <legend id="last-floor" class="font-bold text-lg">{{ $t('lastFloor') }}</legend>
      <div class="flex mt-5 gap-5 lg:gap-15">
        <RadioInput
          id="last-floor-yes"
          name="lastFloor"
          class="w-full"
          :label="$t('yes')"
          value="last"
          rules="required"
        />
        <RadioInput
          id="last-floor-no"
          name="lastFloor"
          class="w-full"
          :label="$t('no')"
          value="repeated"
          rules="required"
        />
      </div>
    </fieldset>
    <fieldset class="mt-5 pb-5 border-b-1 border-tertiary-A600 text-primary-A900">
      <legend id="room-direction" class="font-bold text-lg">
        {{ $t('roomDirection') }}
      </legend>
      <div class="flex mt-5 gap-5 lg:gap-15">
        <RadioInput
          id="room-direction-north"
          name="roomDirection"
          class="w-full"
          :label="$t('north')"
          value="lower"
          rules="required"
        />
        <RadioInput
          id="room-direction-south"
          name="roomDirection"
          class="w-full"
          :label="$t('south')"
          value="upper"
          rules="required"
        />
      </div>
    </fieldset>
    <fieldset class="mt-5 text-primary-A900 w-full md:w-3/4">
      <legend id="room-dimensions" class="font-bold text-lg">
        {{ $t('roomDimensions') }}
      </legend>
      <div class="flex mt-5 gap-5 lg:gap-15">
        <TextInput
          id="room-dimensions-length"
          name="length"
          type="number"
          class="w-full text-base"
          :label="$t('length')"
          unit="m"
          min="1"
          max="100"
          border
        />
        <TextInput
          id="room-dimensions-width"
          name="width"
          type="number"
          class="w-full text-base"
          :label="$t('width')"
          unit="m"
          min="1"
          max="100"
          border
        />
      </div>
    </fieldset>

    <template v-if="meta.valid && !!acHorsePower">
      <Message v-if="compatibilityState === 'compatible'" class="mt-5" type="success" with-icon>
        <template #subtitle>
          {{ $t('compatible') }}
        </template>
      </Message>

      <Message v-else-if="!attributeValue" class="mt-5" type="danger" with-icon>
        <template #subtitle>
          <i18n-t keypath="seeCommercialOptions" tag="p" class="text-primary-A900 mt-3">
            <template #link>
              <AppLink :to="`/commercial`" class="font-bold underline text-primary-A900">
                {{ $t('commercial') }}
              </AppLink>
            </template>
          </i18n-t>
        </template>
      </Message>

      <Message v-else-if="compatibilityState === 'above'" class="mt-5" type="success" with-icon>
        <template #subtitle>
          <i18n-t keypath="compatibleWithAlternative" tag="p" class="mt-3">
            <template #link>
              <button type="button" class="font-bold underline text-primary-A900" @click="toBestFit">
                {{ $t('capacity', { value: hp }) }}
              </button>
            </template>
          </i18n-t>
        </template>
      </Message>

      <Message v-else class="mt-5" type="danger" with-icon>
        <template #subtitle>
          <i18n-t keypath="suggestCapacity" tag="p" class="text-primary-A900 mt-3">
            <template #link>
              <button class="font-bold underline text-primary-A900" type="button" @click="toBestFit">
                {{ $t('capacity', { value: hp }) }}
              </button>
            </template>
          </i18n-t>
        </template>
      </Message>
    </template>

    <template v-if="meta.valid && !attributeValue && typeof acHorsePower === 'undefined'">
      <Message class="mt-5" type="danger" with-icon>
        <template #subtitle>
          <i18n-t keypath="seeCommercialOptions" tag="p" class="text-primary-A900 mt-3">
            <template #link>
              <AppLink :to="`/commercial`" class="font-bold underline text-primary-A900">
                {{ $t('commercial') }}
              </AppLink>
            </template>
          </i18n-t>
        </template>
      </Message>
    </template>
    <AppButton
      class="md:max-w-64 text-lg mt-8 py-3 w-full relative mx-auto lg:mx-0"
      :disabled="!meta.valid || (!!acHorsePower && !isCompatible)"
    >
      {{ $t(acHorsePower ? 'addToCart' : 'showResult') }}
    </AppButton>
  </form>
</template>

<script setup lang="ts">
const props = defineProps({
  acHorsePower: {
    type: Number,
    default: undefined,
  },
});

const { t: $t } = useI18n({
  useScope: 'local',
});

const emit = defineEmits<{
  (e: 'compatible', hp: number): void;
  (e: 'redirected', hp: number): void;
}>();

const { handleSubmit, meta, values } = useAcCalculatorForm();
const { acCalculator } = useAcCalculator();
const { attributes } = await useAttributes();
const { error } = useAlerts();

type CompatibilityState = 'compatible' | 'above' | 'below' | false;

const compatibilityState = computed<CompatibilityState>(() => {
  if (!meta.value?.valid || !props.acHorsePower) return false;

  if (props.acHorsePower === hp.value) return 'compatible';

  return props.acHorsePower > hp.value ? 'above' : 'below';
});

const hp = computed(() => {
  if (!meta.value?.valid) return 0;

  if (!values.lastFloor || !values.roomDirection || !values.length || !values.width) return 0;

  return acCalculator({ dir: values.roomDirection, floor: values.lastFloor, value: values.length * values.width });
});

const attributeValue = computed(
  () =>
    attributes.value
      ?.find(attribute => attribute?.attribute_code === 'ac_horse_power')
      ?.attribute_options?.find(option => {
        return Number(option?.label?.replace(' HP', '')) === hp.value;
      })?.value,
);

const isCompatible = computed(() => {
  return compatibilityState.value === 'compatible' || compatibilityState.value === 'above';
});

const onSubmit = handleSubmit(async () => {
  if (props.acHorsePower) {
    emit('compatible', hp.value);
    return;
  }

  if (attributeValue.value) {
    emit('redirected', hp.value);

    const encodedParams = base64Encode({
      lastFloor: values.lastFloor,
      roomDirection: values.roomDirection,
      length: values.length,
      width: values.width,
    });

    await navigateTo({
      path: 'best-fit',
      query: {
        'title-by': 'ac_horse_power',
        ac_horse_power: attributeValue.value,
        calc: encodedParams,
      },
      force: true,
    });
  } else {
    console.log('AcError', `There is no registered K with these value ${hp.value} `);
    error('AcError', `There is no registered K with these value ${hp.value} `);
  }
});

const toBestFit = () => {
  const encodedParams = base64Encode({
    lastFloor: values.lastFloor,
    roomDirection: values.roomDirection,
    length: values.length,
    width: values.width,
  });

  navigateTo({
    path: 'best-fit',
    query: {
      'title-by': 'ac_horse_power',
      ac_horse_power: attributeValue.value,
      calc: encodedParams,
    },
    force: true,
  });
};
</script>

<i18n>
{
  "en": {
    "ourGuide": "Our Shopping guide",
    "checkCompatibility": "Check Compatibility",
    "fill": " Fill the room information in which the AC is planned to be installed, & we will recommend you the best fit",
    "lastFloor": "Last Floor",
    "yes": "Yes",
    "no": "No",
    "roomDirection": "Room Direction",
    "north": "North",
    "south": "South",
    "roomType": "Room Type",
    "bedroom": "Bedroom",
    "livingRoom": "Living Room",
    "roomDimensions": "Room Dimensions",
    "length": "Length",
    "width": "Width",
    "showResult": "Show Result",
    "compatible": "Product is Compatible",
    "suggestCapacity": "Your Parameters is not Compatible You can find The One matches You through {link}",
    "capacity": " {value} HP",
    "ourShopping": "Our Shopping",
    "guide": "Guide",
    "check": "Check",
    "compatibility": "Compatibility",
    "seeCommercialOptions": "There is no registered HP with these value {value} , you can find the best fit through {link}",
    "commercial": "Commercial",
    "compatibleWithAlternative": "Your Parameters is Compatible, but you can find a better fit through {link}"
  },
  "ar": {
    "ourGuide": "دليل التسوق لدينا",
    "checkCompatibility": "تحقق من التوافق",
    "fill": "املأ معلومات الغرفة التي تخطط لتثبيت المكيف فيها ، وسنوصيك بالمناسب",
    "lastFloor": "الطابق الأخير",
    "yes": "نعم",
    "no": "لا",
    "roomDirection": "اتجاه الغرفة",
    "north": "شمالي",
    "south": "جنوبي",
    "roomType": "نوع الغرفة",
    "bedroom": "غرفة نوم",
    "livingRoom": "غرفة المعيشة",
    "roomDimensions": "أبعاد الغرفة",
    "length": "طول",
    "width": "عرض",
    "showResult": "نتيجة العرض",
    "compatible": "المنتج متوافق",
    "suggestCapacity": "اختياراتك غير متفقه مع المنتج الذى اخترته , يمكن متابعه ما يناسبك من هنا   {link}",
    "capacity": " {value} حصان",
    "ourShopping": "تسوقنا",
    "guide": "دليل",
    "check": "تحقق",
    "compatibility": "التوافق",
    "seeCommercialOptions": "لا يوجد حصان  مسجل بهذه القيمة {value} , يمكنك العثور على الأنسب من هنا {link}",
    "commercial": "تجاري",
    "compatibleWithAlternative": "اختياراتك متوافقه مع المنتج الذى اخترته , ولكن يمكنك العثور على الأنسب من هنا {link}"
  }
}

</i18n>
